import React, {useState, useEffect} from 'react';
import moment from 'moment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faCalendar, faTrash} from '@fortawesome/free-solid-svg-icons';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Common from './ultility/common';

function ActiveDateSelector(props){

    const[displaymodal, setDisplayModal]=useState(false);
    const[datekey, setDateKey]=useState(0);
    const[timedateholder, setTimeDateHolder]=useState("")
    const[timedate, setTimeDate]=useState([]);
    const[dateRequired, setDateRequired]=useState(false);

    const handleRemoveDate=(i)=>{
        var eventdates=[];
        timedate.map((tdate, index) =>{
            if(i !==index){
                eventdates.push(tdate);
            }
        });

        if(eventdates.length===0){setDateRequired(false)}
        setTimeDate(eventdates);
    }

    const handleChange=(e)=>{
        setDateKey(e);
    }

    const formatDate=(epoch)=>{
        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('dddd MMMM Do YYYY, h:mm:ss a');
    }

    

    const handleAddEventDate=(e)=>{
        e.preventDefault();
        const meridiem = datekey.getHours() >= 12 ? "PM" : "AM";
        const tmetadata={
            
            epochtime:Date.parse(datekey),
            year: datekey.getFullYear(),
            month:datekey.getMonth(),
            day: datekey.getDate(),
            hour: datekey.getHours(),
            min: datekey.getMinutes(),
            meridiem: meridiem 
        }


        var day = moment(tmetadata.epochtime); //milliseconds
       
       const updatedArray=[...timedate, tmetadata];
       setTimeDate(updatedArray);
       localStorage.setItem("activeeventdatestate", JSON.stringify(updatedArray));

       if(updatedArray.length>0){setDateRequired(true)}
       setDisplayModal(!displaymodal);  
    }

    useEffect(() => {
        console.info("DateSelector->useEffect :");
        console.info(props)
        //setTimeDate(props.data);
    },[]);

    return(
        <React.Fragment>
        <h4 className="line-lightgrey-1-bottom  p-b-10 p-t-25">
            <span className="font-size-75 clr-1">* &nbsp;&nbsp;&nbsp;</span>
            <button className="icon-text-container-1 font-size-100" onClick={setDisplayModal}>
                <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp; Add Date</button>
                {dateRequired?'':<span className="font-size-75 clr-1">&nbsp; &nbsp;Please Add Date</span>}
           
        </h4>
            {
                timedate?.map((tdate, index) =>{
                    return <Row key={index} className="p-t-10  hoverpos">
                        <Col><FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;&nbsp;{formatDate(tdate.epochtime)}</Col>
                        <Col lg={4}> 
                            <span className="font-size-75" onClick={(s)=>{handleRemoveDate(index)}}>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </Col>
                    </Row>
                })
            }
                             
    
       {/* ADD METADATA MODAL*/}
       <Modal show={displaymodal} onHide={setDisplayModal}>
            <Modal.Header closeButton>
            <Modal.Title>Add Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <Form.Group className="p-b-10">
                        <DatePicker
                            selected={datekey}
                            onChange={(e)=>{handleChange(e)}} 
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="dd MMM yyyy hh:mm:ss a"
                        />
                    </Form.Group>
                     <Button type="submit" onClick={handleAddEventDate}>Add Date</Button>
            </Form>
            </Modal.Body>
        </Modal>               
</React.Fragment>
);

}

export default ActiveDateSelector;