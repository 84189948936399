import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


//INTERNAL
import ImageUpload from '../../components/imageupload';
import DateSelector from '../../components/dateselector';
import ActiveDateSelector from '../../components/activedateselector';
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faQrcode, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';

//STORE
import userDataStore from '../../stores/zuserdatastore';

function AddEventContent() {

    //STORES
    const { userData} = userDataStore();

    const history = useNavigate();


    //FORM Information
    const [validated, setValidated] = useState(false);
    const [formvalidated, setFormvalidated] = useState(false);
    const[isDateSet, setIsDateSet]=useState(false);
    const[isImageSet, setisImageSet]=useState(false)
    const[ticketType, setTicketType]=useState(null);


    const[eventname, setEventname] = useState('');
    const[eventdescription, setEventdescription] = useState('');
    const[venueid, setVenueId]=useState(null);
    const[selVenue, setSelVenue]=useState(null);
    const[venueroomid, setVenueroomid]=useState(null)
    const[taxes, setTaxes]=useState(null);
    const[taxId, setTaxId]=useState(null);

    //Modal Control
    const [showDepartmentContactsModel, setShowDepartmentContactsModel] = useState(false);
    const handleCloseDepartmentContactsModel=()=>{setShowDepartmentContactsModel(false);}
    const [venues, setVenues]=useState([]);
    const[products, setProducts]=useState(null);
    const[selectedProductId, setSelectedProductId]=useState(null);
    


    //Venue Rooms
    const [eventTickets, setEventTickets]=useState([]);
    const[ticketName, setTicketName]=useState(null);
    const[ticketDescription, setTicketDescription]=useState(null);
    const[totalTicket, setTotalTicket]=useState(null);

    const[groupTickets, setGroupTicket]=useState(null);
    const[pricePerTicket, setPricePerTicket]=useState(null);
    const[categories, setCategories]=useState([]);
    const[selCategory, setSelCategory]=useState(null);
    const[dates, setDates]=useState([]);
    const[selectedDate, setSelectedDate]=useState(null);

    //SERVICE FEE
    const[serviceFees, setServiceFees]=useState([]);
    const[serviceFeeCharged, setServiceFeeCharged]=useState([])
    


     //CONTACTS
     const[firstname, setFirstName]=useState(null);
     const[lastname, setLastname]=useState(null);
     const[emailaddress, setEmailAddress]=useState(null);
     const[phone, setPhone]=useState(null);
     const[contactvis, setContactvis]=useState(null);
     const[totalAvailableTickets, setTotalAvailableTickets]=useState(null);

    const[tags, setTags]=useState([]);
    const[selectedgroups, setSelectedGroups]=useState([]);

    /*
    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }
    */

    const manageServiceFee=(fee,value)=>{
        var sfeeCharg=[];

        if(serviceFeeCharged.length===0){

            if(value.target.checked){

                sfeeCharg.push(fee);
                setServiceFeeCharged(sfeeCharg);

            }else{

                setServiceFeeCharged(sfeeCharg);

            }

        }else{
            
            if(value.target.checked){

                serviceFeeCharged.forEach((s)=>{
                    sfeeCharg.push(s);
                });

                sfeeCharg.push(fee);
                setServiceFeeCharged(sfeeCharg);


            }else{

                serviceFeeCharged.forEach((s)=>{

                    if(fee.serviceFeedId !=s.serviceFeedId){
                        sfeeCharg.push(s)
                    }
                })

                setServiceFeeCharged(sfeeCharg);
            }
        }
    }

    const handleTotalAvailableTickets=(t)=>{

        //Need to select venue first 
        if(venueroomid===null){
            toast("Please Select Venue Room First");
        }else{
            selVenue?.venuerooms.map((vroom)=>{
                if(vroom.venueRoomId==venueroomid){
                    if(vroom.venueRoomCapacity<t){
                        toast("Total available tickets cannot exceed room capacity of "+ vroom.venueRoomCapacity);
                    }else{
                        setTotalAvailableTickets(t)

                    }
                }
            })
        }    
    }

    const handleSetVenue=(venid)=>{

        venues.map((v)=>{
            if(v.venueid ===venid){
                setVenueId(venid);
                setSelVenue(v);

            }
       })

    }

    const handleFormateDate=(epoch)=>{
        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('dddd MMMM Do YYYY, h:mm:ss a');
    }

    const handleSetSelectedDate=(e)=>{
        setSelectedDate(dates[e]);
    }

    //THIS IS USED TO ADD THE VENUE ROOMS FROM MODAL
    const handleAddEventTicketForm =(e)=>{
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
      
          setValidated(true);

        var category={}
    
        categories.map((cat)=>{
            if(cat.categoryid === selCategory.categoryId){
                category=cat;
            }
        });
     
       var additionalInfo={};

       if(selCategory.categorycode==="STANDARDTICKET"){
            additionalInfo={
                eventsdata:selectedDate,
            }
       }

       if(selCategory.categorycode==="PRODUCTANDTICKET"){
            additionalInfo={
                productid:selectedProductId,
                eventsdata:selectedDate,
            }
       }

       if(selCategory.categorycode==="EVENTTABLEWITHPRODUCT"){
            additionalInfo={
                productid:selectedProductId,
                groupTickets:groupTickets,
                eventsdata:selectedDate
            }

       }

       var metadata=null;
       if(ticketType !==null){
            metadata=[{
               key:"proddetailfilter",
               value:ticketType
            }];
        }
     

        const eventticket={
            productName:ticketName,
            productDescription:ticketDescription,
            venueid:venueid,
            venueroomid:venueroomid,
            //eventId:""//this is set during insert
            companyid:userData[0].companyid,
            category:selCategory,
            additionalInfo:additionalInfo,
            ticketsIncluded:totalTicket,
            totalAvailable:totalAvailableTickets,
            totalRemaining:totalAvailableTickets,
            metadata:metadata,
           // price:convertString(pricePerTicket),
           price:pricePerTicket,
        }

        eventTickets.push(eventticket);
        setTicketName(null);
        setTicketDescription(null);
        setTotalTicket(null);
        setSelectedProductId(null);
        setShowDepartmentContactsModel(false);
        
    }

    const handleTicketModel=(e)=>{

        var eventDatesIsSet=JSON.parse(localStorage.getItem('eventdatestate'));
        var popup=true;

        if(eventDatesIsSet ===null){
            popup=false;
            toast("1 event date is required to add tickets");
        }
        if(taxId===null){
            popup=false;
            toast("please select a tax code");
        }

        if(venueid===null){
            popup=false;
            toast("please select venue");
        }

        if(popup){
            setShowDepartmentContactsModel(e);
            setDates(eventDatesIsSet);
        }

    }
    const formatServiceFee=(fee)=>{
        var tranCharge="";
        if(fee.serviceFeeChargeBy==="TRANS"){
            tranCharge="transaction";
        }else{
            tranCharge="ticket";
        }
        if(fee.serviceFeeType==="VALUE"){
            return fee.serviceFeeName + " of "+ "$"+fee.serviceFeeAmount+" per " +tranCharge;

        }else{
            return fee.serviceFeeName + " of "+"%"+fee.serviceFeeAmount+" per "+tranCharge;
        }
    }

    const handleSetCategory=(cid)=>{

        categories.map((cat)=>{
            if(cat.categoryid === cid){
                setSelCategory(cat);
            }
        });
    }

    //THIS IS USED TO ADD CONTACT INFO FROM MODAL
    const handleAddContactForm=(e)=>{
        e.preventDefault();
        const contactvisual = <Row>
                <Col lg={10} className="bck-clr-primary-3 center rad-5 p-b-10 p-t-10">
                    <Row><Col className="font-300 clr-primary-6"><FontAwesomeIcon icon={faUsersBetweenLines} /></Col></Row>
                    <Row><Col className="font-125 clr-primary-6 font-heavy">{firstname} {lastname}</Col></Row>
                    <Row><Col className="font-100 clr-primary-6 font-light">{emailaddress}</Col></Row>
                    <Row><Col className="font-100 clr-primary-6 font-light">{phone}</Col></Row>
                </Col>
            </Row>;
            setContactvis(contactvisual);
            setShowDepartmentContactsModel(false);
    }

    const handleCreateEvent = (e)=>{

        setFormvalidated(true)

        const form = e.currentTarget;
        
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }else{
            e.preventDefault();

            var data=null
            if(taxId===null){
                data={
                    eventname: eventname,
                    eventdescription : eventdescription,
                    eventActiveDate:JSON.parse(localStorage.getItem('activeeventdatestate')),
                    venueid: venueid,
                    isTaxes:false,
                    companyid:userData[0].companyid,
                    eventimage:JSON.parse(localStorage.getItem('imagedatastate')),
                    product:eventTickets,
                    displayServiceFee:serviceFeeCharged,
                    eventdates:JSON.parse(localStorage.getItem('eventdatestate'))
                }

            }else{
                data={
                    eventname: eventname,
                    eventdescription : eventdescription,
                    eventActiveDate:JSON.parse(localStorage.getItem('activeeventdatestate')),
                    venueid: venueid,
                    isTaxes:true,
                    taxesId:taxId,
                    companyid:userData[0].companyid,
                    eventimage:JSON.parse(localStorage.getItem('imagedatastate')),
                    product:eventTickets,
                    displayServiceFee:serviceFeeCharged,
                    eventdates:JSON.parse(localStorage.getItem('eventdatestate'))
                }
                
            }

            var val=true;

            if(data.eventdates ===null){
                val=false;
                toast("Event Date is required");
            }

            if(data.eventimage ===null){
                val=false;
                toast("Image  is required");
            }

            if(data.product.length==0){
                val=false;
                toast("1 or more event tickets is required");
            }


            if(val){
                console.info("data: ");
                console.info(data);

                new RestServer().setUrl(producturl+'/api/prod/event')
                .setMethod('POST')
                .flagReturnData(true)
                .setPostBody(data)
                .connect()
                .then(response=>{
                    const rtndata =response.data;
                    if(rtndata.messageTypeID===1){
                        localStorage.removeItem("eventdatestate");
                        localStorage.removeItem("activeeventdatestate");
                        localStorage.removeItem("imagedatastate");
                        
                        history('/events', {replace:true})
                        
                    }else{
                        toast(rtndata.Message);
                    }
                });
                
            }
        }         
    }

    const convertString=(e)=>{
        e=e.replace(/\,/g,''); // 1125, but a string, so convert it to number
        e=parseInt(e,10);

        return e;
    }
    
    useEffect(() => {

        localStorage.removeItem("eventdatestate");
        localStorage.removeItem("activeeventdatestate");

        
        localStorage.removeItem("imagedatastate");

        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setVenues(response.data);
        });

        new RestServer().setUrl(producturl+'/api/servicefee/allcompanyservicefee/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("allcompanyservicefee");
            console.info(response.data);
            setServiceFees(response.data);
        });

        new RestServer().setUrl(orgurl+'/api/org/companytaxes/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setTaxes(response.data);
        });

        new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            var cat=[];
            const allcat=response.data;
            allcat.map((c)=>{
                if(c.usedFor==="EVENTTICKETS"){
                    cat.push(c); 
                }
            })
            setCategories(cat);
        });

        new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setProducts(response.data);
        });

    },[]);
    

    return (
    <React.Fragment>
        <Row>
            <Form noValidate validated={formvalidated} onSubmit={handleCreateEvent}>
            <Col lg={12} md={12}>
                <Row>
                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col> Event Information</Col>
                        </Row>
                        <Row className="glass-container-1">
                            <Col>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="eventname" onChange={(s)=>{setEventname(s.target.value)}}>
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Event Name </Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder="event name" required/>
                                        <Form.Control.Feedback type="invalid" className="font-size-75">Event Name is Required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col>
                                    <Form.Group controlId ="eventdescription" onChange={(s)=>{setEventdescription(s.target.value)}}>
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Event Description</Form.Label>
                                        <Form.Control as="textarea" rows={4} required/>
                                        <Form.Control.Feedback type="invalid" className="font-size-75">EventDescription is Required</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Venue</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{handleSetVenue(s.target.value)}} required>
                                            <option>Select Venue...</option>
                                            {
                                                venues.map((venue)=>{
                                                    return <option value={venue.venueid}>{venue.venuename}</option>
                                                })
                                            }
                                            <Form.Control.Feedback type="invalid" className="font-size-75">Please select a venue</Form.Control.Feedback>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label><span className="font-size-75 clr-1">*</span> Taxes</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setTaxId(s.target.value)}} >
                                            <option>Select Tax...</option>
                                            {
                                                taxes?.map((tax)=>{
                                                    return <option value={tax.companyTaxId}>{tax.taxName}</option>
                                                })
                                            }
                                            <Form.Control.Feedback type="invalid" className="font-size-75">Please select a Event Tax</Form.Control.Feedback>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="p-t-25">
                                <Col lg={12}>
                                    <Row><Col><span className="font-size-75 clr-1">*</span> Activate Event</Col></Row>
                                    <Row><Col className="font-size-75 clr-1">Event will not be active until this date has passed</Col></Row>
                                    <Row><Col><ActiveDateSelector /></Col></Row>
                                </Col>
                            </Row>

                            <Row className="p-t-25">
                                <Col lg={12}>
                                    <Row><Col><span className="font-size-75 clr-1">*</span> Event Date</Col></Row>
                                    <Row><Col><DateSelector /></Col></Row>
                                </Col>
                            </Row>
                            <Row className="p-t-25">

                            </Row>
                    
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Add Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>

                        <Row className="p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Service Fees</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row className="p-l-25">
                                            <Col className="font-size-75 p-l-5">Fees that are enabled will be passed onto your Customer and will not be absorbed by you</Col>
                                        </Row>
                                        
                                            {
                                                serviceFees?.map((fee)=>{
                                                    return  <Row className="p-t-25 p-l-25">
                                                        <Col lg={10}>{formatServiceFee(fee)} </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="switch"
                                                                id={fee.serviceFeedId}
                                                                onChange={(e)=>{manageServiceFee(fee, e)}}
                                                            />
                                                        </Col>
                                                    </Row> 
                                                })
                                            } 
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                       {/* 
                        <Row className="p-b-25 p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Tags</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row>
                                            <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                        </Row>
                                        <Row className="p-t-25 p-l-25 p-r-25">
                                            {
                                                tags.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                                })
                                            }
                                        
                                        </Row>  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        */}
                    </Col>
                    <Col lg={4} md={12} className="p-r-25 p-b-25">
                         {/* CONTACTS & Tags */}
                <Row >
                    <Col>
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col lg={6}> Add Event Tickets</Col>
                            <Col lg={6} className="left" onClick={(s)=>{handleTicketModel(true)}}> <FontAwesomeIcon icon={faPlusSquare} /></Col>
                        </Row>
                        <Row>
                            <Col >
                                {
                                    eventTickets.map((ticket)=>{
                                        return<Row className="p-b-20 p-r-20">
                                        <Col lg={12} className="glass-container-1 p-25 ">
                                            <Row className="p-b-10">
                                                <Col lg={4} className="font-size-300 font-heavy9"><FontAwesomeIcon icon={faQrcode} /> </Col>
                                                <Col lg={6}><span className="font-size-125 font-heavy9 p-t-20">{ticket.productName}</span></Col>
                                            </Row>
                                            <Row><Col className="font-size-100 clr-primary-6 font-light p-b-10">{ticket.productDescription}</Col></Row>
                                            <Row><Col className="font-size-75 clr-primary-6 font-heavy">Total Tickets: {ticket.totalAvailable}</Col></Row>
                                        </Col>
                                    </Row>;
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                    </Col>
                </Row>
            </Col>

            <Col lg={5}  md={5} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={4} className="center">
                        <Button className="icon-text-container-0 p-10 font-heavy9" type="submit">
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Create
                        </Button>
                    </Col>
                
                    <Col lg={4}  md={5} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
            </Form>
        </Row>

         {/*Add Event Ticket*/}
         <Modal show={showDepartmentContactsModel} onHide={handleCloseDepartmentContactsModel}>
            <Form noValidate validated={validated} onSubmit={handleAddEventTicketForm}>
            <Modal.Header closeButton>
            <Modal.Title>Event Ticket Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="ticketname" onChange={(s)=>{setTicketName(s.target.value)}}>
                            <Form.Label>Event Ticket Name</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="event ticket name" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="ticketdescription" onChange={(s)=>{setTicketDescription(s.target.value)}}>
                            <Form.Label>Event Ticket Description</Form.Label>
                            <Form.Control as="textarea" rows={4} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Venue Room</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{setVenueroomid(s.target.value)}} required>
                                <option>Select Venue Room...</option>
                                    {
                                        selVenue?.venuerooms?.map((venueroom)=>{
                                            return <option value={venueroom.venueRoomId}>{venueroom.venueRoomName}</option>
                                        })
                                    }
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="ticketname" onChange={(s)=>{handleTotalAvailableTickets(s.target.value)}}>
                            <Form.Label>Total Available Tickets</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="total available tickets" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Categories</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{handleSetCategory(s.target.value)}} required>
                                <option>Select Category...</option>
                                    {
                                        categories.map((category)=>{
                                            return <option value={category.categoryid}>{category.categoryname}</option>
                                        })
                                    }
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Ticket Type</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{setTicketType(s.target.value)}} required>
                                <option>Select Type...</option>
                                <option value="GENERAL">GENERAL</option>
                                <option value="FREE">FREE</option>
                                <option value="VIP">VIP</option>
                                <option value="PREMIUM">PREMIUM</option>
                            </Form.Control >
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Dates</Form.Label>
                            <Form.Control as="select" onChange={(s)=>{handleSetSelectedDate(s.target.value)}} required>
                                <option>Select Date...</option>
                                    {
                                        dates?.map((date, index)=>{
                                            return <option value={index}>{handleFormateDate(date.epochtime)}</option>
                                        })
                                    }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                
                {((selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setGroupTicket(s.target.value)}} required>
                            <Form.Label>Number of Tickets</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="Number of Tickets" />
                        </Form.Group>
                    </Col>
                </Row>:"")} 
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setTotalTicket(s.target.value)}} required>
                            <Form.Label>Number Of Ticket(s) Included</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="Tickets Included" />
                        </Form.Group>
                    </Col>
                </Row>
               
                <Row className="p-t-10">
                    <Col>
                        <Form.Group controlId ="totalticket" onChange={(s)=>{setPricePerTicket(s.target.value)}} required>
                            <Form.Label>Ticket Price</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder="ticket price" />
                        </Form.Group>
                    </Col>
                </Row>
                {((selCategory?.categorycode==="PRODUCTANDTICKET" || selCategory?.categorycode==="EVENTTABLEWITHPRODUCT")?<Row className="p-t-10">
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Products</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setSelectedProductId(s.target.value)}}>
                            <option>Select Product...</option>
                                {
                                    products?.map((product)=>{
                                        return <option value={product.productId}>{product.productName}</option>
                                    })
                                }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>:"")}  
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDepartmentContactsModel}>
                Cancel
            </Button>
            <Button variant="primary" type="submit">
                Add Ticket
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>

    </React.Fragment>
    );
}

export default AddEventContent;